<template>
  <div class="record-view-box">
    <view-card title="报告模板列表">
      <div class="content" slot="content">
        <div class="table-tools-box">
          <div class="filter-item">
            <span>名称</span>
            <el-input v-model="keyWordFilter" placeholder="请输入内容"></el-input>
          </div>
          <div class="filter-item">
            <span>创建人</span>
            <el-input v-model="keyWordFilter" placeholder="请输入内容"></el-input>
          </div>
          <div class="filter-item">
            <button class="search-button" @click="search">搜索</button>
          </div>
          <div class="create-button">
            <div @click="uploadReport" style="display: flex;margin-right: 10px;align-items: center;">
              <svg-icon icon-class="upload" :size="1.5"></svg-icon>
              <span class="create-text">上传模版</span>
            </div>

            <div @click="toCreateView" style="display: flex;align-items: center;">
              <svg-icon icon-class="add" :size="1.5"></svg-icon>
              <span class="create-text">创建模板</span>
            </div>

          </div>
        </div>
        <div class="table-content">
          <Table :option="tableOptin" ref="Table" :data="tableData" :isShowPagination="true" :showTotal="true"
            :total="total" @Change="pageChange"></Table>
        </div>
      </div>
    </view-card>
  </div>
</template>

<script>
import Table from "@/components/Table";
import { getList } from "@/api/report";
import handleFn from "./common";
import Modal from "./uploadModal"
export default {
  name: "recordManagementView",
  components: {
    Table,
  },
  data() {
    return {
      timeFilter: "",
      keyWordFilter: "",
      tableOptin: [],
      total: 0,
      tableData: [],
    };
  },
  methods: {
    toCreateView() {
      this.$router.push({ name: "createReport" });
    },
    pageChange(e) {
      this.getData(e);
    },
    search() {
      let pageSize = this.$refs.Table.pageSize;
      this.getData({ pageSize, pageNum: 1, mfileName: this.keyWordFilter });
    },
    getData(params = { pageSize: 10, pageNum: 1, mfileName: "" }) {
      getList(params)
        .then((e) => {
          this.tableData = e.rows;
          this.total = e.total;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    Refresh() {
      let pageSize = this.$refs.Table.pageSize,
        pageNum = this.$refs.Table.pageNum;
      this.getData({ pageSize, pageNum });
    },
    uploadReport() {
      Modal({
        cb: this.Refresh,
        token: this.$store.getters.token,
      });
    }
  },
  created() {
    this.getData();
    this.tableOptin = handleFn.TABLE_OPTION(this);
    window.PageOfficeCbFn = () => {
      this.Refresh();
    };
  },
};
</script>

<style lang="less" scoped>
.record-view-box {
  background-color: #ffffff;
  border-radius: 10px;
  height: 100%;

  .content {
    gap: 20px;
    display: flex;
    flex-flow: column;
    height: 100%;

    .table-content {
      flex: 1;
    }
  }
}
</style>
@/api/record
