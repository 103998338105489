vue
<template>
  <div class="report-upload-model">
    <el-dialog :visible.sync="dialogVisible" class="dialog" title="上传报告模板" :show-close="true">
      <div class="report-item">
        <div class="select-box">
          <span class="_text">请选择报告类型</span>
          <el-select v-model="reportType" placeholder="请选择报告类型">
            <el-option v-for="item in reportTypeList" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="report-upload-box">
          <el-upload class="upload-demo" drag :action="uploadUrl" :auto-upload="false" :multiple="false" :limit="1"
            accept=".xlsx" :headers="headers" :file-list="fileList" ref="recordUpload" :on-success="uploadSuccess"
            :on-exceed="exceed" :on-error="onError" :data="uploadData" :before-upload="beforeUpload">
            <i class="el-icon-upload"></i>
            <div class="el-upload__text" style="text-align: center;">将文件拖到此处，或<em>点击上传</em><br />
              只能上传xlsx文件</div>
          </el-upload>
        </div>
      </div>
      <div slot="footer">
        <el-button type="primary" @click="upload">上传</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import _ from "loadsh";
import { getReportType } from "@/api/report";
export default {
  data() {
    return {
      dialogVisible: true, // 对话框可见性
      uploadUrl: "/api/CreateMexcel/uploadMexcel",
      fileList: [],
      reportType: "",
      reportTypeList: []
    }
  },
  computed: {
    uploadData: function () {
      return {
        fileType: this.reportType,
      }
    },
    headers: function () {
      return {
        Authorization: "Bearer " + this.token,
      }
    }
  },
  props: {
    destroy: {
      type: Function,
    },
    token: {
      type: String,
    },
    cb: {
      type: Function
    }
  },
  watch: {
    dialogVisible(val) {
      if (!val) {
        if (this.cb && typeof this.cb == "function") {
          this.cb();
        }
        this.destroy?.();
      }
    },
  },
  methods: {
    beforeUpload() {
      if (_.isEmpty(this.reportType)) {
        this.$modal.msgWarning("请选择报告类型");
        return false;
      }
    },
    uploadSuccess(event) {
      if (event.code === 200) {
        this.$modal.msg("文件上传成功");
      } else {
        this.$modal.msgError(event.msg);
      }
    },
    onError(err) {
      this.$modal.msgError(err);
    },
    exceed() {
      this.$modal.msgWarning("单次只允许上传一个文件");
    },
    upload() {
      this.$refs.recordUpload.submit();
    },
    getReportTypeList() {
      getReportType().then((res) => {
        this.reportTypeList = res;
      });
    },
  },
  created() {
    this.getReportTypeList();
  },
};
</script>
<style lang="less" scoped>
.dialog {
  width: 1200px;
  margin: auto;

  .report-item {
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .select-box {
      display: flex;
      align-items: center;
      justify-content: "left";
      width: 100%;
      gap: 10px;
      margin-bottom: 10px;
    }
  }
}
</style>