import { changeSatus, delExcel } from "@/api/report";
import { getOpenPath } from "@/utils/common";
import model from "@/plugins/modal"
const disableRecordhandl = async function (cb) {
  model.confirm("确定禁用该条记录本模板吗？", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "info",
  })
    .then(() => {
      cb();
    })
}
const openRecordHandle = async function (cb) {
  model.confirm("确定开启该条记录本模板吗？", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "info",
  })
    .then(() => {
      cb();
    })
}
const deleteRecordHandle = async function (cb) {
  model.confirm("确定删除该条记录本模板吗？", "提示", {
    confirmButtonText: "确定",
    cancelButtonText: "取消",
    type: "warning",
  })
    .then(() => {
      cb()
    })
    .catch(() => { });
}


export default {
  disableRecordhandl,
  openRecordHandle,
  deleteRecordHandle,
  TABLE_OPTION: (context) => {
    return {
      columns: [
        {
          prop: "zexcelId",
          label: "ID",
          width: 20,
        },
        {
          prop: "zfileName",
          label: "名称",
          width: 30,
        },
        {
          prop: "createBy",
          label: "创建人",
          width: 10,
        },
        {
          prop: "createTime",
          label: "创建时间",
          width: 20,
        },
      ],
      operate: [
        {
          class: "table-btn warning-buttton", //操作按钮显示类型
          label: "编辑", //按钮显示文字
          key: "look",
          handle: (e) => {
            POBrowser.openWindow(
              "/PgOffice/reportEditor",
              "width=980px;height=800px;",
              JSON.stringify({ zfileDir: e.zfileDir })
            );
          },
        },
        {
          class: "table-btn primary-button", //操作按钮显示类型
          label: "查看", //按钮显示文字
          key: "look",
          handle: (e) => {
            POBrowser.openWindow(
              "/PgOffice/SimpleFinalExcelreadOnly",
              "width=980px;height=800px;",
              JSON.stringify({ zfileDir: e.zfileDir })
            );
          },
        },
        {
          class: "table-btn danger-button", //操作按钮显示类型
          label: "删除", //按钮显示文字
          key: "delete",
          handle: (e) => {
            deleteRecordHandle(() => {
              let { zexcelId, zfileDir } = e;
              delExcel({ zexcelId, zfileDir }).then((e) => {
                context.Refresh();
              });
            });
          },
        },
      ],
      operateWith: 25,
    }
  }
}

// {
//   class: (row) => {
//     if (row.status) {
//       return "table-btn warning-buttton";
//     }
//     return "table-btn info-button";
//   }, //操作按钮显示类型
//   label: (row) => {
//     if (row.status) {
//       return "禁用";
//     }
//     return "可用";
//   }, //按钮显示文字
//   key: "iSable",
//   handle: (e) => {
//     let { status, mexcelId } = e;
//     if (status) {
//       disableRecordhandl(() => {
//         changeSatus({ status: 0, mexcelId }).then((e) => {
//            context.Refresh();
//         });
//       })
//     } else {
//       openRecordHandle(() => {
//         changeSatus({ status: 1, mexcelId }).then(() => {
//            context.Refresh();
//         });
//       })
//     }
//   },
// },